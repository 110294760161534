// If you want to override variables do it here

@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';


@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';

// date range picker
@import "rsuite/dist/rsuite.css";

html,
body {
  font-family: 'Zen Maru Gothic', sans-serif;
  // scrollbar-width: none;
}

html,
body {
  margin: auto;
  height: 100%;
  overflow: auto;
}

.btn,
.btn-group {
  margin: 0;
  border-color: #b1b7c1;
}

.col-md-3 {
  margin-bottom: 0.5rem;
}
.col-md-2 {
  margin-bottom: 0.5rem;
}

.nav-link {
  border-radius: 5px;
}

// .sidebar-nav{
//   left : 10px;
// }
.simplebar-content {
  margin-left: 5px;
}

.sidebar-nav .nav-link.active {
  box-shadow: 0px 3px 15px 10px #ebc999;
  scrollbar-width: thin;
}

textarea.form-control {
  height: calc((29px / 1rem) * 1rem) !important;
  min-height: 0 !important;
}

.table > :not(caption) > * > * {
  padding: 0.5;
}
.table > :not(caption) > * > * > input {
  // height: 10px;
  min-height: 25px !important;
}

table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(50%);
  height: 40vh;
}

thead {
  flex: 0 0 auto;
  width: 100%;
}

tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.chartjs-tooltip {
  position: absolute;
  z-index: $zindex-sticky + 1;
  padding: ($spacer * .25) ($spacer * .5);
  color: #fff;
  height: 100px;
  width: 200px;
  pointer-events: none;
  background: rgba(0, 0, 0, .7);
  opacity: 0;
  @include transition(all .15s ease);
  transform: translate(-50%, 0);
  @include border-radius($border-radius);
}



